<template>
  <div class="peopleReaction">
    <div class="peopleReaction-t">
      <div class="img"><img src="./img/renda.png" alt="" /></div>
      <div class="h3">民有所呼 我有所应</div>
    </div>
    <div class="peopleReaction-c">
      <div class="item">
        <div class="h4">【居民说事】范围：</div>
        <div class="p">
          反映事项包括社区治理、交通安全、安全生产、生态环境、农林水利、困难救助、文教体育、卫生健康.其他等方面建议意见。
        </div>
      </div>
      <div class="item">
        <div class="h4">【不予受理】范围：</div>
        <div class="p">
          涉及本人或者其他个人问题的事项，申诉、控告、检举、信访的事项，已进入仲裁、行政复议、诉讼等法定流程的事项等。
        </div>
      </div>
    </div>
    <div class="peopleReaction-b">
      <div class="btn" @click="tofankui">居民反映</div>
      <div class="btn" @click="toList">进度查询</div>
    </div>
    <div class="back"><img src="./img/rendaback.png" alt="" /></div>
  </div>
</template>

<script>
import { toRegister } from "@/utils/common";
export default {
  name: "peopleReaction",
  data() {
    return {};
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
  },
  methods: {
    async tofankui() {
      if (
        await toRegister(this.$route.path, this.$route.query, "热门活动报名")
      ) {
        this.$router.push({
          name: "peopleReactionForm",
        });
      }
    },
    toList() {
      this.$router.push({
        name: "peopleReactionList",
      });
    },
  },
  created() {},
  mounted() {
    if (this.$route.query.latitude) {
      localStorage.setItem("latitude", this.$route.query.latitude);
    }
    if (this.$route.query.longitude) {
      localStorage.setItem("longitude", this.$route.query.longitude);
    }
  },
};
</script>

<style lang="less" scoped>
.peopleReaction {
  background: #fff7f1;
  position: relative;
  padding: 46px 52px 0;
  box-sizing: border-box;
  min-height: 100vh;
  overflow: hidden;
  .peopleReaction-t {
    .img {
      width: 190px;
      height: 200px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .h3 {
      text-align: center;
      font-weight: 700;
      font-size: 48px;
      color: #ff0200;
      line-height: 66px;
      margin: 30px auto 46px;
    }
  }
  .peopleReaction-c {
    .item {
      margin-bottom: 52px;
      .h4 {
        font-weight: 700;
        font-size: 32px;
        color: #ff0200;
        line-height: 44px;
        margin-bottom: 24px;
      }
      .p {
        font-weight: 400;
        font-size: 28px;
        color: #666666;
        line-height: 40px;
      }
    }
  }
  .peopleReaction-b {
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 72px;
    .btn {
      width: 300px;
      height: 88px;
      background: #ff0200;
      border-radius: 44px;
      font-weight: 700;
      font-size: 36px;
      color: #ffffff;
      line-height: 88px;
      text-align: center;
      &:nth-child(2) {
        background: #fc823a;
      }
    }
  }
  .back {
    width: 100%;
    height: 692px;
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
